import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip';

import ToastElement from "../uiComponents/ToastElement";
import SingleSelectElement from '../uiComponents/SingleSelectElement';
import MultiSelectElement from '../uiComponents/MultiSelectElement';
import TextboxElement from '../uiComponents/TextboxElement';
import CheckboxElement from '../uiComponents/CheckboxElement';
import ToggleElement from '../uiComponents/ToggleElement';
import TrackingSystemService from '../../services/trackingSystem/TrackingSystemService';
import Moment from 'moment';
import Cookies from 'universal-cookie';

import ReactSummernoteLite from '@easylogic/react-summernote';
import '@easylogic/react-summernote/dist/index.css';

const TrackingSystemCreateModal = (props) => {

    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [activeBuList, setActiveBuList] = useState([]);
    const [selectedBuDetails, setSelectedBuDetails] = useState({});
    const [basicData, setBasicData] = useState({});
    const [filterData, setFilterData] = useState({});
    const [requestTypes, setRequestTypes] = useState([]);
    const [jobName, setJobName] = useState('');
    const [clientId, setClientId] = useState('');
    const [projectList, setProjectList] = useState([]);
    const [projectId, setProjectId] = useState(null);
    const [deadline, setDeadline] = useState();
    const [isTenatative, setIsTentative] = useState(false);
    const [priorityId, setPriorityId] = useState();
    const [jobList, setJobList] = useState([]);
    const [parentJobId, setParentJobId] = useState(null);
    const [usages, setUsages] = useState([]);
    const [dimension, setDimension] = useState(null);
    const [fontFamily, setFontFamilty] = useState(null);
    const [outputFileType, setOutputFileType] = useState(null);
    const [printSpec, setPrintSpec] = useState(null);
    const [printCheck, setPrintCheck] = useState(false);

    const toastRef = useRef();
    const cookies = new Cookies();
    var userId = cookies.get('userId');
    var buId = cookies.get('buId');

    useEffect(() => {
        getUserDetails(userId);
        getActiveBu();
        getBasicData();
        getFilterData(buId);
    }, [])

    const getUserDetails = (userId) => {
        TrackingSystemService.getUserDetails(userId).then(data => {
            setUserDetails(data)
        })
    }

    const getActiveBu = () => {
        TrackingSystemService.getActiveBu().then(data => {
            const buDetails = data.find(element => element.id === buId);
            setSelectedBuDetails(buDetails);
            setActiveBuList(data);
        })
    }

    const getBasicData = () => {
        TrackingSystemService.getBasicData().then(data => {
            setBasicData(data);
        })
    }

    const getFilterData = (dataId) => {
        TrackingSystemService.getFilterData(dataId).then(data => {
            setFilterData(data);
        })
    }

    const onBuChange = (dataId) => {
        const buDetails = activeBuList.find(element => element.id === dataId);
        setSelectedBuDetails(buDetails);
        TrackingSystemService.getFilterData(dataId).then(data => {
            setFilterData(data);
        })
    }

    const onClientChange = (e) => {
        
        setClientId(e.value.id);

        TrackingSystemService.getProjectsByClientId(e.value.id).then(data => {
            setProjectList(data);
        })

        TrackingSystemService.getJobsByClientId(e.value.id).then(data => {
            const jobs = data;
            if (jobs && jobs.length > 0) {
                // Sort jobList based on the latest time (updatedAt)
                const sortedList = jobs.sort((a, b) => {
                    const aUpdatedAt = new Date(a.updatedAt).getTime();
                    const bUpdatedAt = new Date(b.updatedAt).getTime();
                    return bUpdatedAt - aUpdatedAt;
                });
                setJobList(sortedList);
            }
        })
    }

    const onChangeUsages = (e) => {
        setUsages(e.value);
        // When Print removed from usages, set print check to false and print specifications to empty string
        if (!e.value.includes('Print')) {
            setPrintCheck(false);
            setPrintSpec('');
        }
    }

    const onChangePrintCheck = (e) => {
        setPrintCheck(e.checked);
        // When Print Check set to false, set print specificatipons to empty string
        if (e.checked == false) {
            setPrintSpec('');
        }
    }

     /* Summernote Reference */
     let editor = null;

     if (editor) {
        editor.summernote("code", "");
    }

    const postNewJob = () => {

        let statusId = '';
        let timeZoneIST = false;
        let deadline_ms = new Date(deadline).getTime()

        filterData.statuses.forEach(element => {
            if(element.status === 'Created')
                statusId = element.id
        })
        
        if(Moment().utcOffset() === 330)
            timeZoneIST = true;
        
        setLoading(true);

        if(!requestTypes || !jobName || !clientId || !deadline) {
            setLoading(false)
            toastRef.current.showToast({
                type: 'error',
                message: 'Please fill mandatory fields'
            })
        } else {

            const data = {
                jobs: {
                    isGlobalJob: "Yes",
                    deadline: deadline,
                    isTenatative: isTenatative,
                    requestType: requestTypes,
                    jobReference: jobName,
                    clientId: clientId,
                    projectId: projectId,
                    parentLink: parentJobId,
                    priorityId: priorityId,
                    usage: usages,
                    printSpec: printSpec,
                    printCheck: printCheck,
                    sizeDimensions:dimension,
                    fontFamily: fontFamily,
                    opfileType: outputFileType,
                    description: '',
                    statusId: statusId,
                    userId: userDetails.id,
                    timeZoneIST: timeZoneIST,
                    manualJobTime: [{}],
                    deadline_ms: deadline_ms,
                    buId: selectedBuDetails.id,
                    createdBuId: buId,
                    description: editor.summernote("code")
                },
                attachments: [{}]
            }
    
            TrackingSystemService.createJob(data).then((data) => {
               if(!data.success){
                setLoading(false);

                toastRef.current.showToast({
                    type: 'error',
                    message: data.message,
                })
            }else{
                 let message = {
                    type: data.success ? 'success' : 'error',
                    summary: data.success ? 'Success' : 'Something went wrong',
                    message: data.message
                }
                let success = data.success ? true : false
                props.onHide(message, success)
            }
            })
        }

    }

    const renderFooter = (name) => {
        return (
            <div>
                <div className='p-grid request-footer'>
                    <div className='p-col-12 align-center justify-end pb-0'>
                        <Button type="submit" label="Add" className="create-button request-button" onClick={() => postNewJob()} disabled={loading} />
                    </div>
                </div>
            </div>
        );
    }

    const currentDate = new Date();

    return (
        <div className="dialog-demo">
            <ToastElement ref={toastRef} />
            <div className="card">
                <Dialog header={props.header} visible={props.visible} onHide={() => props.onHide()} breakpoints={{ '960px': '75vw' }} footer={renderFooter()} draggable={false}>
                    <div className='leave-request tracking-create'>
                        <div className="p-grid">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Business Unit <span className='text-red'>*</span></p>
                                <SingleSelectElement name="" placeholder="Select" optionLabel="name" options={activeBuList} value={selectedBuDetails} onChangeValue={(e) => onBuChange(e.value.id)}/>
                            </div>
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Priority</p>
                                <SingleSelectElement placeholder="Select" optionLabel="priority" options={filterData.priorities} onChangeValue={(e) => setPriorityId(e.value.id)} />
                            </div>
                        </div>
                        <div className="p-grid mt-5">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Request Type <span className='text-red'>*</span></p>
                                <MultiSelectElement 
                                    placeholder="Select" 
                                    optionLabel="name" 
                                    options={basicData.requestTypes} 
                                    maxSelectLabel={1} 
                                    onChangeValue={(e) => setRequestTypes(e.value)}
                                />
                            </div>
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Job Name <span className='text-red'>*</span></p>
                                <h5 className='dropdown-heading'>{props.dropdownName}</h5>
                                <TextboxElement placeholder="Job Name" onChangeText={(e) => setJobName(e.target.value)}></TextboxElement>
                            </div>
                        </div>
                        <div className="p-grid mt-5">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Client <span className='text-red'>*</span></p>
                                <SingleSelectElement name="" placeholder="Select" optionLabel="clientName" options={filterData.clients} onChangeValue={(e) => onClientChange(e)} />
                            </div>
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Project</p>
                                <SingleSelectElement name="" placeholder="Select" optionLabel="name" options={projectList} onChangeValue={(e) => setProjectId(e.value.id)} />
                            </div>
                        </div>
                        <div className="p-grid mt-5 mb-13">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Deadline <span className='text-red'>*</span></p>
                                <h5 className='dropdown-heading'></h5>
                                <Calendar
                                    name='deadline'
                                    placeholder='Select'
                                    view='date'
                                    dateFormat='M dd, yy'
                                    onChange={(e) => setDeadline(e.target.value)}
                                    value={deadline}
                                    minDate={currentDate}
                                    showTime={true}
                                    stepMinute={5}
                                    showIcon
                                />
                            </div>
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Tentative Date</p>
                                <h5 className='dropdown-heading'></h5>
                                <div className="align-center toggle-button">
                                    <Tooltip target=".toggleButton" />
                                    <span data-pr-tooltip={'Click YES or NO'} data-pr-position="right" className="toggleButton">
                                        <ToggleElement
                                            onChange={(e) => setIsTentative(e)}
                                            onIcon='pi pi-check'
                                            offIcon='pi pi-times'
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="p-grid mt-5">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Priorty</p>
                                <SingleSelectElement placeholder="Select" optionLabel="priority" options={filterData.priorities} onChangeValue={(e) => setPriorityId(e.value.id)} />
                            </div>
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Parent Link</p>
                                <SingleSelectElement placeholder="Select" optionLabel="jobReference" options={jobList} onChangeValue={(e) => setParentJobId(e.value.id)} />
                            </div>
                        </div>
                        <div className="p-grid mt-5">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Usage</p>
                                <MultiSelectElement 
                                    placeholder="Select" 
                                    optionLabel="name" 
                                    options={basicData.usages}
                                    maxSelectLabel={1}
                                    // onChangeValue={(e) => setUsages(e.value)}
                                    onChangeValue={(e) => onChangeUsages(e)}
                                />
                            </div>
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Size/Dimensions</p>
                                <h5 className='dropdown-heading'>{props.dropdownName}</h5>
                                <TextboxElement placeholder="" onChangeText={(e) => setDimension(e.target.value)}></TextboxElement>
                            </div>
                        </div>
                        <div className="p-grid mt-5">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Font Family</p>
                                <h5 className='dropdown-heading'>{props.dropdownName}</h5>
                                <TextboxElement placeholder="" onChangeText={(e) => setFontFamilty(e.target.value)}></TextboxElement>
                            </div>
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Output File Type</p>
                                <h5 className='dropdown-heading'>{props.dropdownName}</h5>
                                <TextboxElement placeholder="" onChangeText={(e) => setOutputFileType(e.target.value)}></TextboxElement>
                            </div>
                        </div>
                        <div className="p-grid mt-5 mb-13">
                            <div className="p-col-12 p-sm-6">
                                <p className="font-normal fs-13 text-lightgray m-0">Print Specifications</p>
                                <h5 className='dropdown-heading'>{props.dropdownName}</h5>
                                <TextboxElement placeholder="" value={printSpec} onChangeText={(e) => setPrintSpec(e.target.value)} disabled={!printCheck}></TextboxElement>
                            </div>
                            <div className="p-col-12 p-sm-6">
                                <h5 className='dropdown-heading'>{}</h5>
                                <CheckboxElement
                                    value={'Please tick this if IG are supplying printing'}
                                    checked={printCheck}
                                    onChangeCheck={(e) => onChangePrintCheck(e)}
                                    className='font-normal fs-13 text-lightgray'
                                    disabled={!usages.includes("Print")}
                                />
                            </div>
                        </div> */}
                        <ReactSummernoteLite placeholder="Type Your Message.." height={200}
                            onInit={({ note }) => {
                                editor = note;
                            }}
                        />
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default TrackingSystemCreateModal;