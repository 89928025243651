import React, { useEffect, useState } from 'react';
import NotificationService from '../../services/notification/NotificationService';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Cookies from 'universal-cookie';

function NotificationList() {

  const cookies = new Cookies();
  const userId = cookies.get('userId');
  
  const [notifications, setNotifications] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    fetchNotifications();
  }, [userId]);

  const fetchNotifications = async () => {
    try {
      const data = await NotificationService.getNotification(userId);
      setNotifications(data);
      updateNotificationCount(data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const updateNotificationCount = (data) => {
    const newCount = data.filter(notification => notification.status === 'NEW').length;
    setNotificationCount(newCount);
  };

  const toggleDropdown = async () => {
    if (!dropdownVisible) {
      await resetNotificationCounter();
    }
    setDropdownVisible((prev) => !prev);
  };

  const resetNotificationCounter = async () => {
    try {
      await NotificationService.resetNotification(userId);
      setNotificationCount(0);
    } catch (error) {
      console.error('Error resetting notification counter:', error);
    }
  };

  const clearNotifications = () => {
    NotificationService.clearNotification(userId)
      .then((response) => {
        setNotifications([]); 
        fetchNotifications(); 
        setNotificationCount(0);
        setDropdownVisible(false);
      })
      .catch((error) => {
        console.error('Error clearing notifications:', error);
      });
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  return (
    <div className="notification-list" style={{ position: 'relative' }}>
      <i className="bi bi-bell header-notification" onClick={toggleDropdown} style={{ cursor: 'pointer' }} >
        {notificationCount > 0 && (
          <span className='count-badge'>{notificationCount}</span>
        )}
      </i>

      {dropdownVisible && (
        <div 
          className='dropdown-content' 
          onMouseLeave={handleMouseLeave}
        >
         
        <div className='notification-card'>
          <div className='notification-lists'>
              {notifications.length > 0 ? (
                notifications.map((notification, index) => (
                <div
                    key={notification.id} style={{ padding: '10px', borderBottom: '1px solid #e3e3e3', cursor: 'pointer', backgroundColor: index === notifications.length - 1 ? 'none' : 'transparent',
              }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f7f7f7')}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                >
              <div className="row">
                  <div className="col m2"></div>
                    <div className="col m10 m-l-5">
                        <div className="row no-gutter">
                          <div className="col m8 p-l-0">
                            <img src={notification.sender.imageUrl} className="circle-img-sm" />
                            &nbsp;&nbsp;
                            <span className="notification-title">
                              {notification.title}
                            </span>
                        </div>
                      <div className="col m4 right-align">
                            <span style={{ fontFamily: 'Montserrat',fontSize: "10px", color: "#808080", float: "right" }}>
                              {new Date(notification.createdAt).toLocaleString("en-US", { month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true })}
                            </span>
                    </div>
                        </div>
              <div className="row no-gutter">
                  <div className="col m12 p-l-0">
                      <span style={{fontFamily: 'Montserrat', fontSize: "12px", color: "#757575" }}>
                        <strong>{notification.sender.firstName} {notification.sender.lastName}</strong> {notification.subject}
                      </span>
                    </div>
                  </div>
              </div>
          </div>
      </div>
            ))
            ) : (
              <div className="no-notification">
                <span >No new notifications</span>
              </div>
              )}
            </div>
            {notifications.length > 0 && (
              <div style={{ display: 'flex', justifyContent: 'right', marginTop: '10px', marginRight: '15px' }}>
                <button
                 className='clearBtn'
                  onClick={clearNotifications} 
                >
                  Clear All
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NotificationList;
