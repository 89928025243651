import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL2;

async function getNotification(userId) {

    let response = await axios.get(`${baseUrl}/api/v1/notification/${userId}`);
    return response.data;
}

async function resetNotification(userId) {
  
    let response = await axios.put(`${baseUrl}/api/v1/resetNotification/${userId}`);
    return response.data;
}

async function clearNotification(userId) {
   
    let response = await axios.put(`${baseUrl}/api/v1/clearNotification/${userId}`);
    return response.data;
}

export default {getNotification,resetNotification,clearNotification};