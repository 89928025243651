// React Component
import { React, useState, useEffect, useRef, createContext } from 'react';

// App CSS
// import './App.css';

// React Router
import { Route, Routes, useNavigate } from 'react-router-dom';

// Util Service, Interceptor
// Components
import JinLogin from './components/login/Login';
import Layout from './components/layout/Layout';
import 'primeflex/primeflex.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Leave from './components/leave/Leave';
import BehalfOf from './components/approval/BehalfOf';
import CompOff from './components/approval/CompOff';
import LeaveApproval from './components/approval/LeaveApproval';
// import Travel from './components/travel/Travel';
import BusinessUnit from './components/businessUnit/BusinessUnit';
import RolesConfig from './components/controlPanel/RolesConfig';
import Employees from './components/employee/Employees';
import LeaveConfig from './components/controlPanel/LeaveConfig';
// import TimesheetConfig from './components/controlPanel/TimesheetConfig';
import ReferenceData from './components/controlPanel/ReferenceData';
// import TravelApproval from './components/approval/TravelApproval';
// import TimesheetApproval from './components/approval/TimesheetApproval';
// import TimesheetExtensionApproval from './components/approval/TimesheetExtensionApproval';
// import Permission from './components/approval/Permission';
import BusinessCreationPage from './components/businessUnit/BusinessCreationPage';
import EmployeeCreationPage from './components/employee/EmployeeCreationPage';
import Clients from './components/clients/Clients';
import Projects from './components/projects/Projects';
// import ServiceDesk from './components/serviceDesk/ServicedeskCreation';
// import ServiceDeskEdition from './components/serviceDesk/ServiceDeskEdition';
import TrackingSystem from './components/trackingSystem/TrackingSystem';
import JobDetailedView from './components/trackingSystem/JobDetailedView';
import TrackingSystemDashboards from './components/trackingSystem/TrackingSystemDashboards';
// import ProjectConfig from './components/controlPanel/ProjectConfig';
// import KRAConfig from './components/controlPanel/KRAConfig';
import HolidayConfig from './components/controlPanel/HolidayConfig';
// import ProjectCreation from './components/projects/ProjectCreation';
import ServiceDeskConfig from './components/controlPanel/ServiceDeskConfig';
import LoginService from './services/login/LoginService';
import ClientCreation from './components/clients/ClientCreation';
import JinLoginError from './components/login/JinLoginError';
// import TimesheetCreation from './components/timesheet/TimesheetCreation';
import ToastElement from "./components/uiComponents/ToastElement";
import Cookies from 'universal-cookie';
// import TimeSheet from './components/timesheet/TimeSheet';
// import SurveyTemplateList from './components/survey/SurveyTemplateList';
// import SurveyTemplatePreview from './components/survey/SurveyTemplatePreview';
// import SurveyTemplate from './components/survey/SurveyTemplateCreation';
// import UserSurveys from './components/survey/UserSurveys';
// import SurveyCreation from './components/survey/SurveyCreation';
// import UserSurveyCreate from './components/survey/UserSurveyCreate';
// import SurveyPreviewResponses from './components/survey/SurveyPreviewResponses';
import AccessControl from './components/admin/AccessControl';
// import CompanyInfo from './components/admin/CompanyInfo';
import PerformanceSettings from './components/controlPanel/PerformanceSettings';
// import WfhRequest from './components/leave/WfhRequest';
// import WfhApproval from './components/approval/WfhApproval';
// import Performances from './components/KRA/Kras';
// import PerformanceCreatePage from './components/KRA/KraCreationPage';
// import PerformanceEditPage from './components/KRA/KraEditPage';
// import UserKra from './components/KRA/UserKra';
// import UserKraPreview from './components/KRA/UserKraPreview';
// import Dashboard from './components/dashboard/Dashboard';
import ResetUserPassword from './components/login/ResetUserPassword';
// import ExpenseCreation from './components/expense/ExpenseCreation';
// import ExpensesApproval from './components/approval/ExpensesApproval';
// import ExpensesPaidApproval from './components/approval/ExpensesPaidApproval';
// import ExpenseView from './components/expense/ExpenseView';
// import Expense from './components/expense/Expense';
// import Resourcing from './components/resourcing/Resourcing';
// import Training from './components/training/Training';
import AdminService from '../src/services/admin/AdminService';
import { jwtInterceptor } from '../src/auth-interceptor/jwtInterceptor';
import Reports from '../src/components/trackingSystem/reports';
// import WSRCreation from './components/wsr/WSRCreation';
// import WSRProjectEdit from './components/wsr/WSRProjectEdit';
// import Feedback from './components/feedback/feedback';
// import Userfeedback from './components/feedback/userfeedback';
// import Feedbackhome from './components/feedback/feedbackhome';
// import AdminFeedback from './components/feedback/adminfeedback';
// import Biometric from './components/biometric/Biometric';
// import FeedbackReport from './components/feedback/feedbackreport';
// import Reports from './components/reports/Reports';
// import ReportCreation from './components/reports/ReportCreation';
// import ReportModification from './components/reports/ReportModification';

import 'survey-core/defaultV2.min.css'; // do not change

import { StylesManager } from "survey-core";
// import CompetencyModalElement from './components/competency/competency';
// import Profile from './components/profile/Profile';

StylesManager.applyTheme("defaultV2"); // Do not change

export const UserContext = createContext();


const App = () => {

	jwtInterceptor()

	const [createPageTitle, setCreatePageTitle] = useState({
		setPageTitle: false,
		pageTitle: '',
		title: ''
	});
	const [header, setHeader] = useState(false)
	const [selectedUser, setSelectedUser] = useState('');
	const [newJoinee, setNewJoinee] = useState(false);

	let navigate = useNavigate();

	const toastRef = useRef();

	const cookies = new Cookies();

	useEffect(() => {
		getMenus();
	}, []);

	const getMenus = () => {
		var userId = cookies.get('userId');
		var roles = cookies.get('roles');
		if (userId) {
			AdminService.getMenusByUserId(userId).then(data => {
				const ids = data.data.map(o => o.id);
				const filtered = data.data.filter(({ id }, index) => !ids.includes(id, index + 1));
				var filterData = _.filter(filtered, (item) => item.isActive);
				if (filterData) {
					if (roles !== undefined && roles.includes('Super Admin')) {
						var filteredData = _.filter(filterData, (item) => item.isAdmin);
						loginRedirection(filterData);
					} else {
						var filteredData = _.filter(filterData, (item) => !item.isAdmin);
						loginRedirection(filteredData);
					}
				}
			});
		} else {
			navigate(`/`);
		}
	}

	const loginRedirection = (pathData) => {
		var name = '';
		var value = ''
		var allcookies = decodeURIComponent(document.cookie);

		var cookiearray = allcookies.split('; ');
		var cookie_name = {};

		for (var i = 0; i < cookiearray.length; i++) {
			name = cookiearray[i].split('=')[0];
			value = cookiearray[i].split('=')[1];
			cookie_name[name] = value;
		}

		if (cookie_name.accessToken) {
			let data = {
				email: cookie_name.email
			}
			LoginService.loginUser(data).then(data => {
				if (data.posted.success && data.posted.accountStatus === 'ACTIVATED') {
					cookies.set('userId', data.posted.userId, { path: '/' });
					cookies.set('buId', data.posted.buId, { path: '/' });
					cookies.set('userName', data.posted.name, { path: '/' });
					cookies.set("roles", JSON.stringify(data.posted.roles), { path: '/' });
					cookies.set("isAuth", true);
					cookies.set("buName", data.posted.buName, { path: '/' });

					let path = pathData.filter(f => {
						return ('/' + f.menuName.replace(/ +/g, '').toLowerCase()) == location.pathname.toLowerCase()
					});
					var locationData = ((!location.pathname.startsWith('/competency/view/')) && (!location.pathname.startsWith('/wsr')) && (!location.pathname.startsWith('/jobs')) && (!location.pathname.startsWith('/trackingSystemDashboards') && (!location.pathname.startsWith('/Reports'))))
					if (path.length === 0 && locationData) {
						navigate('/trackingSystem')
					}
				} else {
					toastRef.current.showToast({
						type: 'error',
						summary: data.posted.message,
						message: data.posted.message
					})
					navigate(`/loginError`);
				}
			})
		} else if (cookies.get('isAuth') == undefined && !location.pathname.includes('/resetPassword')) {
			navigate(`/`);
		}
	}

	const buCreationPage = (createBuPage) => {
		setCreatePageTitle({
			setPageTitle: createBuPage,
			pageTitle: 'Business Unit Creation',
			title: 'Create'
		})
	}

	const buEditPage = (editBuPage) => {
		setCreatePageTitle({
			setPageTitle: editBuPage,
			pageTitle: 'Business Unit Edit',
			title: 'Edit'
		})
	}

	const buListPage = (listBuPage) => {
		setCreatePageTitle({
			setPageTitle: listBuPage,
			pageTitle: 'Business Unit'
		})
	}

	const employeeCreationPage = (create) => {
		setCreatePageTitle({
			setPageTitle: create,
			pageTitle: 'Employee Creation',
			title: 'Create'
		})
	};

	const employeeEditPage = (edit) => {
		setCreatePageTitle({
			setPageTitle: edit,
			pageTitle: 'Employee Edit',
			title: 'Edit'
		})
	}

	const employeeListPage = (list) => {
		setCreatePageTitle({
			setPageTitle: list,
			pageTitle: 'Employees'
		})
	};

	const clientCreationPage = (create) => {
		setCreatePageTitle({
			setPageTitle: create,
			pageTitle: 'Client Creation'
		})
	};

	const clientEditPage = (edit) => {
		setCreatePageTitle({
			setPageTitle: edit,
			pageTitle: 'Client Edit'
		})
	}


	const serviceDeskEditPage = (edit) => {
		setCreatePageTitle({
			setPageTitle: edit,
			pageTitle: 'Service Desk Edit'
		})
	};

	const timesheetCreationPage = (createTimesheetPage) => {
		setCreatePageTitle({
			setPageTitle: createTimesheetPage,
			pageTitle: 'Timesheet'
		})
	}

	const timesheetEditPage = (editTimesheetPage) => {
		setCreatePageTitle({
			setPageTitle: editTimesheetPage,
			pageTitle: 'Timesheet'
		})
	}

	const timesheetListPage = (listTimesheetPage) => {
		setCreatePageTitle({
			setPageTitle: listTimesheetPage,
			pageTitle: 'Timesheet'
		})
	}

	const surveyCreationPage = (data) => {
		setCreatePageTitle({
			setPageTitle: data,
			pageTitle: 'Survey Creation'
		})

	}

	const templateCreationPage = (data) => {
		setCreatePageTitle({
			setPageTitle: data,
			pageTitle: 'Template Creation'
		})
	}

	const performanceListPage = (listKraPage) => {
		setCreatePageTitle({
			setPageTitle: listKraPage,
			pageTitle: 'Kra'
		})
	}

	const performanceCreatePage = (createKraPage) => {
		setCreatePageTitle({
			setPageTitle: createKraPage,
			pageTitle: 'Kra'
		})
	}

	const performanceEditPage = (editKraPage) => {
		setCreatePageTitle({
			setPageTitle: editKraPage,
			pageTitle: 'Kra'
		})
	}

	const userKraEditPage = (listUserKra) => {
		setCreatePageTitle({
			setPageTitle: listUserKra,
			pageTitle: 'KRA'
		})
	}

	const userKraListPage = (editUserKra) => {
		setCreatePageTitle({
			setPageTitle: editUserKra,
			pageTitle: 'KRA'
		})
	}

	const setTitle = (data, title) => {
		setCreatePageTitle({
			setPageTitle: data,
			pageTitle: title
		})
	}

	const selectedId = (data, newJoinee) => {
		setSelectedUser(data)
		setNewJoinee(newJoinee)
	}

	const expenseCreationPage = (create) => {
		setCreatePageTitle({
			setPageTitle: create,
			pageTitle: 'Expenses'
		})
	};

	const expenseEditPage = (create) => {
		setCreatePageTitle({
			setPageTitle: create,
			pageTitle: 'Expenses'
		})
	};

	const headerValue = (value) => {
		setHeader(value)
	}

	const expenseListPage = (list) => {
		setCreatePageTitle({
			setPageTitle: list,
			pageTitle: 'Expenses'
		})
	};

	const trainingPage = (create) => {
		setCreatePageTitle({
			setPageTitle: create,
			pageTitle: 'Competency Tracker'
		})
	};

	const wsrCreationPage = (create) => {
		setCreatePageTitle({
			setPageTitle: create,
			pageTitle: 'Weekly Status Report',
			title: 'Create'
		})
	};

	const profilePage = (page) => {
		setCreatePageTitle({
			setPageTitle: page,
			pageTitle: 'Profile'
		})
	}

	const wsrEditPage = (edit) => {
		setCreatePageTitle({
			setPageTitle: edit,
			pageTitle: 'WSR Edit',
			title: 'Edit'
		})
	}

	const reportListPage = (list) => {
		setCreatePageTitle({
			setPageTitle: list,
			pageTitle: 'Reports'
		})
	}

	const reportEditPage = (edit) => {
		setCreatePageTitle({
			setPageTitle: edit,
			pageTitle: 'Edit Report',
		})
	};

	const reportCreatePage = (create) => {
		setCreatePageTitle({
			setPageTitle: create,
			pageTitle: 'Add Reports'
		})
	};

	const setValue = (data) => {
		setHeader(data)
	}

	return (
		<div className="app">
			<ToastElement ref={toastRef} />
			<Routes>
				<Route path="/" element={<JinLogin />} />
				<Route path="/loginError" element={<JinLoginError />} />
				<Route path="/resetPassword/:userId/:forgotId/:token" element={<ResetUserPassword />} />
			</Routes>
			{
				cookies.get('isAuth') !== undefined &&
				<Routes>
					<Route path="/admin" element={
						<UserContext.Provider value={createPageTitle}>
							<Layout setTitle={setTitle} IsSetTitle={true} value={header} setValue={setValue} />
						</UserContext.Provider>
					}>
						<Route path="accessControl" element={<AccessControl />} />
						{/* <Route path="companyInfo" element={<CompanyInfo />} /> */}
					</Route>
					<Route path="/" element={
						<UserContext.Provider value={createPageTitle}>
							<Layout value={header} setTitle={setTitle} IsSetTitle={true} setValue={setValue} />
						</UserContext.Provider>
					}>
						<Route path="leave" element={<Leave />} />
						<Route path="leaveApproval" element={<LeaveApproval />} />
						<Route path="compOff" element={<CompOff />} />
						<Route path="behalfOf" element={<BehalfOf />} />
						{/* <Route path="travel" element={<Travel />} /> */}
						<Route exact path="businessUnit" element={<BusinessUnit buCreationPage={buCreationPage} buEditPage={buEditPage} buListPage={buListPage}
						/>} >
							<Route path="create" element={<BusinessCreationPage />} />
							<Route path="edit" element={<BusinessCreationPage />} />
						</Route>
						{/* <Route path="dashboard" element={<Dashboard />} /> */}
						<Route path="roles" element={<RolesConfig />} />
						<Route path="referenceData" element={<ReferenceData />} />
						<Route path="performanceSettings" element={<PerformanceSettings />} />
						<Route path="leaveConfiguration" element={<LeaveConfig />} />
						<Route path="holidayConfig" element={<HolidayConfig />} />
						<Route path="reports" element={<Reports />} />
						<Route path="employees" element={<Employees employeeCreationPage={employeeCreationPage} employeeEditPage={employeeEditPage} employeeListPage={employeeListPage}
						/>} >
							<Route path="create" element={<EmployeeCreationPage />} />
							<Route path="edit" element={<EmployeeCreationPage />} />
						</Route>
						<Route path="clients" element={<Clients />} >
							<Route path="create" element={<ClientCreation />} />
							<Route path="edit" element={<ClientCreation />} />
						</Route>
						<Route path='jobs/:jobId/bu/:buId/*' element={<JobDetailedView />} />
						<Route path="trackingSystemConfiguration" element={<ServiceDeskConfig />} />
						<Route path='trackingSystemDashboards' element={<TrackingSystemDashboards />} />
						{/*<Route path="timesheetConfiguration" element={<TimesheetConfig />} />													</Route>
						<Route path="timesheetConfiguration" element={<TimesheetConfig />} />
						<Route path="performanceSettings" element={<PerformanceSettings />} />*/
						/*<Route path="travelApproval" element={<TravelApproval />} />
						<Route path="TimesheetApproval" element={<TimesheetApproval />} />
						<Route path="allocationExtensionApproval" element={<TimesheetExtensionApproval />} />

						<Route path="permission" element={<Permission />} />
					*/}
						<Route path="projects" element={<Projects />} >
							{/* <Route path="create" element={<ProjectCreation />} />
							<Route path="edit" element={<ProjectCreation />} /> */}
						</Route>
						{/*<Route path="training" element={<Training  value ={headerValue} projectCreationPage={trainingPage} projectEditPage={projectEditPage} projectListPage={projectListPage} />} ></Route>
						<Route path="kra" element={<Performances performanceListPage={performanceListPage} performanceCreatePage={performanceCreatePage} performanceEditPage={performanceEditPage} />} >
							<Route path="create" element={<PerformanceCreatePage />} />
							<Route path="edit" element={<PerformanceCreatePage />} />
							<Route path="preview" element={<PerformanceEditPage />} />
						</Route>
						<Route path="projectConfig" element={<ProjectConfig />} />
						<Route path="servicedesk" element={<ServiceDesk />}>
							<Route path="edit" element={<ServiceDeskEdition />} />
						</Route>*/
							<Route path='trackingSystem' element={<TrackingSystem />} />
						/*<Route path="kraConfiguration" element={<KRAConfig />} />
						<Route path="timesheet" element={<TimeSheet timesheetCreationPage={timesheetCreationPage} timesheetEditPage={timesheetEditPage} timesheetListPage={timesheetListPage} />} >
							<Route path="create" element={<TimesheetCreation />} />
							<Route path="edit" element={<TimesheetCreation />} />
						</Route>
						<Route path="SurveyTemplatePreview" element={<SurveyTemplatePreview />} />
						<Route path="createSurveyTemplate" element={<SurveyTemplate />} />
						<Route path="createSurvey" element={<SurveyCreation />} />
						<Route path="surveyPreview" element={<SurveyPreviewResponses />} />

						<Route path="surveyCreation" element={<SurveyTemplateList surveyCreationPage={surveyCreationPage} templateCreationPage={templateCreationPage} />} >
							<Route path="create" element={<SurveyTemplate />} />
						</Route>
						<Route path="survey" element={<UserSurveys />} >
							<Route path="fill" element={<UserSurveyCreate />} />
						</Route>
						<Route path="wfhRequest" element={<WfhRequest />} />
						<Route path="wfhApproval" element={<WfhApproval />} />
						<Route path="userKra" element={<UserKra userKraEditPage={userKraEditPage} userKraListPage={userKraListPage} />} >
							<Route path="preview" element={<UserKraPreview />} />
						</Route>
						<Route path="kraReview" element={<UserKra userKraEditPage={userKraEditPage} userKraListPage={userKraListPage} />} >
							<Route path="preview" element={<UserKraPreview />} />
						</Route>
						<Route path="ats" />
						<Route path="expensesApproval" element={<ExpensesApproval />} />
						<Route path="expensesPaidApproval" element={<ExpensesPaidApproval />} />
						<Route path="expense" element={<Expense expenseCreationPage={expenseCreationPage} expenseListPage={expenseListPage} expenseEditPage={expenseEditPage} />}>
							<Route path="create" element={<ExpenseCreation />} />
							<Route path="edit" element={<ExpenseCreation />} />
						</Route>
						<Route path="expense/view" element={<ExpenseView />} />
						<Route path="resourcing" element={<Resourcing selectedId={selectedId}/>} />
						<Route path="training" element={<Training />} />
						<Route path="/competency/view/:selectedUser/:newJoinee"  element={<CompetencyModalElement /> }/>
						<Route path="wsr" element={<WSRCreation wsrCreationPage={wsrCreationPage} wsrEditPage={wsrEditPage}/>}>
						</Route>
						<Route path="/wsr/:view/:projectId/:startDate/:endDate/:filter"  element={<WSRProjectEdit /> }/>
						
						<Route path = '/profile' element={<Profile profilePage={profilePage} />} />
						<Route path = '/feedback' element={<Feedbackhome feedbackListPage={feedbackListPage} />}/>
						<Route path = '/feedback/user' element={<Userfeedback />}/>
						<Route path = '/feedback/:feedbackId/:idEdit' element={<Feedback feedbackEditPage={feedbackEditPage} />}/>
						<Route path = '/feedback/create' element={<Feedback feedbackCreatePage={feedbackCreatePage} />}/>
						<Route path = '/feedback/admin' element={<AdminFeedback/>}/>
						<Route path='/biometric' element= {<Biometric/>}/>
						<Route path = "/feedback/report" element={<FeedbackReport/>}/>
						<Route path="reports" element={<Reports value={headerValue} reportEditPage={reportEditPage} reportCreatePage ={reportCreatePage} reportListPage={reportListPage} />}>
                        </Route>	
						<Route path="reports/create" element={<ReportCreation />} />
                        <Route path="reports/edit" element={<ReportModification />} /> */}
					</Route>
				</Routes>
			}
		</div>
	);
}

export default App;