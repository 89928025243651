import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import Moment from 'moment';
import momentTz from 'moment-timezone';
import Cookies from 'universal-cookie';

import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';

import DataTableElement from "../uiComponents/DataTableElement";
import ToastElement from "../uiComponents/ToastElement";
import Loader from '../uiComponents/Loader';
import TrackingSystemService from '../../services/trackingSystem/TrackingSystemService';
import TrackingSystemCreateModal from "./TrackingSystemCreateModal";

function TrackingSystem(props) {

    const [userDetails, setUserDetails] = useState({});
    const [usersList, setUsersList] = useState([]);
    const [allQueueList, setAllQueueList] = useState([]);
    const [myQueueList, setMyQueueList] = useState([]);
    const [completedQueueList, setCompletedQueueList] = useState([]);
    const [recentQueueList, setRecentQueueList] = useState([]);
    const [tagName, setTagName] = useState("");
    const [activeIndex, setActiveIndex] = useState();
    const [datatable, setDatatable] = useState(true);
    const [button, setButton] = useState(false);
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [displayCreateModal, setDisplayCreateModal] = useState(false);
    // const navigate = useNavigate();

    const location = useLocation();
    const toastRef = useRef();
    const cookies = new Cookies();

    var buId = cookies.get('buId');
    var userId = cookies.get('userId');
    var roles = cookies.get('roles');
    var isGlobalUser = cookies.get('isGlobalUser');

    var timeZoneIST = false;
    if (Moment().utcOffset() === 330)
        timeZoneIST = true;

    useEffect(() => {
        getUserDetails(userId);
        getUsersList();
    }, [])

    useEffect(() => {
        if (location.state == null) {
            setButton(true);
            setDatatable(true);
            if (localStorage.getItem("activeTabIndex") === 0) {
                setTagName("All Queue");
            } else if (localStorage.getItem("activeTabIndex") == 1) {
                setTagName("My Queue");
            } else if (localStorage.getItem("activeTabIndex") == 2) {
                setTagName("Completed Queue");
            } else if (localStorage.getItem("activeTabIndex") == 3) {
                setTagName("Recent Queue");
            }
            getAllQueue();
        } else {
            setDatatable(false);
        }
    }, [success])

    useEffect(() => {
        const savedIndex = localStorage.getItem("activeTabIndex");
        if (savedIndex !== null) {
            setActiveIndex(parseInt(savedIndex, 10));
        }
    }, []);

    // useEffect(() => {
    //     const params = new URLSearchParams(location.search);
    //     const tabIndex = params.get("tab");
    //     if (tabIndex !== null) {
    //         setActiveIndex(parseInt(tabIndex, 10));
    //     }
    // }, [location.search]);

    useEffect(() => {
        if (activeIndex) {
            roles.includes('User') && roles.length === 1 ? 1 : 3
        }
    }, [activeIndex])

    const getUserDetails = (userId) => {
        TrackingSystemService.getUserDetails(userId).then(data => {
            setUserDetails(data)
        })
    }

    const getUsersList = () => {
        TrackingSystemService.getUsersList().then(data => {
            setUsersList(data.users);
        })
    }

    const getAllQueue = (e) => {
        setLoading(true);
        let filterData = {
            isGlobalUser: isGlobalUser,
            deliveryComplete: false,
            createdQueue: false,
            myQueue: false,
        };

        let filter = JSON.stringify(filterData);

        var dataValues = {
            buId: buId,
            direction: 'ASC',
            limit: e?.rows ? e?.rows : 20,
            offset: e?.page ? e?.page : '',
            pattern: e?.search ? e?.search : '',
            sortBy: 'deadline_ms',
            filter: filter
        }

        setLoading(true);
        TrackingSystemService.getJobs(dataValues).then(data => {
            setAllQueueList(data);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getMyQueue = (e) => {
        setLoading(true);
        let filterData = {
            isGlobalUser: isGlobalUser,
            deliveryComplete: false,
            createdQueue: false,
            myQueue: true,
            users: [userId],
            userId: userId
        };

        let filter = JSON.stringify(filterData);

        var dataValues = {
            buId: buId,
            direction: 'ASC',
            limit: e?.rows ? e?.rows : '',
            offset: e?.page ? e?.page : '',
            pattern: e?.search ? e?.search : '',
            sortBy: 'deadline_ms',
            filter: filter
        }

        TrackingSystemService.getJobs(dataValues).then(data => {
            setMyQueueList(data);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getCompletedQueue = (e) => {
        setLoading(true);
        let filterData = {
            isGlobalUser: isGlobalUser,
            deliveryComplete: true,
            createdQueue: false,
            myQueue: false,
        };

        let filter = JSON.stringify(filterData);

        var dataValues = {
            buId: buId,
            direction: 'ASC',
            limit: e?.rows ? e?.rows : '',
            offset: e?.page ? e?.page : '',
            pattern: e?.search ? e?.search : '',
            sortBy: 'updatedAt',
            filter: filter
        }

        TrackingSystemService.getJobs(dataValues).then(data => {
            setCompletedQueueList(data);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getRecentQueue = (e) => {
        let filterData = {
            isGlobalUser: isGlobalUser,
            deliveryComplete: false,
            createdQueue: true,
            myQueue: false,
        };

        let filter = JSON.stringify(filterData);

        var dataValues = {
            buId: buId,
            direction: 'ASC',
            limit: e?.rows ? e?.rows : '',
            offset: e?.page ? e?.page : '',
            pattern: e?.search ? e?.search : '',
            sortBy: 'deadline_ms',
            filter: filter
        }

        TrackingSystemService.getJobs(dataValues).then(data => {
            setRecentQueueList(data);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onChangeFilter = (e) => {
        if (tagName === 'All Queue') {
            getAllQueue(e);
        } else if (tagName == 'My Queue') {
            getMyQueue(e);
        } else if (tagName == 'Completed Queue') {
            getCompletedQueue(e);
        } else if (tagName == 'Recent Queue') {
            getRecentQueue(e);
        }
    }

    const selectData = (selectData) => {
        return ''
    }

    const dialogFuncMap = {
        'displayCreateModal': setDisplayCreateModal
    }

    const onHide = (name, message, success) => {
        if (name) {
            dialogFuncMap[`${name}`](false);
        }
        if (success) {
            setSuccess(success)
        }
        if (message) {
            setLoading(true);
            toastRef.current.showToast(message);
            setLoading(false);
        }
    }

    const createView = () => {
        setDisplayCreateModal(true);
    }

    const setData = (data) => {
        let label = data.originalEvent.target.textContent;
        setTagName(label)
    }

    const bpsUsersTemplate = (jobDetails) => {
        var bpsData = []
        if (jobDetails.bpsUsers != null) {
            jobDetails.bpsUsers.forEach((bpsUserId, index) => {
                usersList.forEach(user => {
                    if (user.id == bpsUserId)
                        bpsData.push(user.firstName);
                })
            });
        }

        const tooltipContentbpsUser = bpsData.join(', '); 
    
        return (
            <>  
                {bpsData.length > 1 
                    ? (
                        <span className="tooltip">
                            {bpsData[0]}, <span style={{ color: '#1c1469' }}>{bpsData.length - 1}+</span>
                            <span className="tooltip-text">{tooltipContentbpsUser}</span>
                        </span>
                    ) 
                    : bpsData.length === 1
        ? <span>{bpsData[0]}</span> 
        : null
                }       
            </>
        );
    };

    const qcUsersTemplate = (jobDetails) => {
        var qcData = []
        if (jobDetails.qcUsers != null) {
            jobDetails.qcUsers.forEach((qcUserId, index) => {
                usersList.forEach(user => {
                    if (user.id == qcUserId) {
                        qcData.push(user.firstName)
                    }
                })         
            });
        }
        const tooltipContentqcUsers = qcData.join(', '); 
        return (
            <>  
                {qcData.length > 1 
                    ? (
                        <span className="tooltip">
                            {qcData[0]}, <span style={{ color: '#1c1469' }}>{qcData.length - 1}+</span>
                            <span className="tooltip-text">{tooltipContentqcUsers}</span>
                        </span>
                    ) 
                    : qcData.length === 1
        ? <span>{qcData[0]}</span>
        : null
                }
            </>
        );
    }

    const deadlineTemplate = (jobDetails) => {
        let deadlineDate = new Date(parseInt(jobDetails.deadline_ms));
        jobDetails.offsetHrs = momentTz().tz('Europe/London').utcOffset();
        jobDetails.deadline = new Date(deadlineDate.getTime() + (deadlineDate.getTimezoneOffset() * 60000) + (jobDetails.offsetHrs) * 60000);
        jobDetails.offsetHrs1 = momentTz().tz('Asia/Kolkata').utcOffset();
        jobDetails.deadlineIST = new Date(deadlineDate.getTime() + (deadlineDate.getTimezoneOffset() * 60000) + (jobDetails.offsetHrs1) * 60000);
        const deadline = timeZoneIST ? jobDetails.deadlineIST : jobDetails.deadline;
        const formattedDeadline = Moment(deadline).format('MMM D, YYYY HH:mm');
        const className = jobDetails.isTentative ? 'text-red' : '';

        return (
            <p className={className}>{formattedDeadline}</p>
        );
    }

    const priorityTemplate = (jobDetails) => {
        return (
            <p>{jobDetails.job_priority ? jobDetails.job_priority.priority : ''}</p>
        )
    }

    const statusTemplate = (jobDetails) => {
        if (jobDetails.job_status?.status) {
            return (
                <div>
                    {
                        <div style={{"background": jobDetails.job_status.badgeColor}} className="status-button">{jobDetails.job_status.status}</div>
                    }
                </div>
            )
        }
    }

    const createDateTemplate = (jobDetails) => {
        return (
            <p>{Moment(jobDetails.createdAt).format('MMM D, YYYY HH:mm')} </p>
        )
    }

    const productionDateTemplate = (jobDetails) => {
        const productionTime = jobDetails?.productionTime;
        const formattedDate = productionTime && Moment(productionTime).isValid() ? Moment(productionTime).format('MMM D, YYYY HH:mm') : '-';
        return <p>{formattedDate}</p>;
    }

    const deliveryDateTemplate = (jobDetails) => {
        return (
            Moment(jobDetails.deliveryTime).isValid() ? <p>{Moment(jobDetails.deliveryTime).format('MMM D, YYYY HH:mm')}</p> : <span>-</span>
        )
    }

    const createdByTemplate = (jobDetails) => {
        return (
            <p>
                {jobDetails.isIGPortal === false ? `${jobDetails.creater.firstName} ${jobDetails.creater.lastName}` : jobDetails.igPortalUserName ? jobDetails.igPortalUserName : `${jobDetails.creater.firstName} ${jobDetails.creater.lastName}`}
            </p>
        )
    }

    const updateDateTemplate = (jobDetails) => {
        let updaterName = '';
        usersList.forEach((user) => {
            if (user.id == jobDetails.updaterId)
                updaterName = user.firstName;
        })

        return (
            <p>{Moment(jobDetails.updatedAt).format('MMM D, YYYY HH:mm')}</p>
        )
    }

    const columns = [
        { field: "client.clientName", header: "Client", sortable: false, style: { width: "30px" }},
        { field: "project.name", header: "Project", sortable: false, style: { width: "30px" } },
        { field: "jobReference", header: "Job Name", sortable: false, style: { width: "100px" } },
        { field: "bpsUsers", header: "BPS", body: bpsUsersTemplate, sortable: false, style: { width: "30px" } },
        { field: "qcsUsers", header: "QC", body: qcUsersTemplate, sortable: false, style: { width: "30px" } },
        {
            field: timeZoneIST ? "deadlineIST" : "deadline",
            header: timeZoneIST ? "Deadline (IST)" : "Deadline (UK)",
            body: deadlineTemplate,
            sortable: false,
            style: { width: "120px" }
        },
        { field: "job_priority.priority", header: "Priority", body: priorityTemplate, sortable: false, style: { width: "100px" } },
        { field: "statuses.status", header: "Status", body: statusTemplate, sortable: false, style: { width: "100px" } },
        { field: "jobNumber", header: "Job Num", sortable: false, style: { width: "100px" } },
        { field: "ER", header: "Total Job Estimate", sortable: false, style: { width: "150px" } },
        { field: "totalStopWatchTime", header: "Total Job Time", sortable: false, style: { width: "120px" } },
        { field: "createdAt", header: "Created Date", body: createDateTemplate, sortable: false, style: { width: "120px" } },
        { field: "creater.firstName", header: "Created By", body: createdByTemplate, sortable: false, style: { width: "100px" } },
        { field: "updatedAt", header: "Updated At", body: updateDateTemplate, sortable: false, style: { width: "120px" } },
    ];

    const completedQueueColumns = [
        { field: "client.clientName", header: "Client", sortable: false, style: { width: "100px" } },
        { field: "project.name", header: "Project", sortable: false, style: { width: "100px" } },
        { field: "jobReference", header: "Job Name", sortable: false, style: { width: "100px" } },
        { field: "bpsUsers", header: "BPS", body: bpsUsersTemplate, sortable: false, style: { width: "100px" } },
        { field: "qcsUsers", header: "QC", body: qcUsersTemplate, sortable: false, style: { width: "100px" } },
        {
            field: timeZoneIST ? "deadlineIST" : "deadline",
            header: timeZoneIST ? "Deadline (IST)" : "Deadline (UK)",
            body: deadlineTemplate,
            sortable: false,
            style: { width: "320px" }
        },
        { field: "job_priority.priority", header: "Priority", body: priorityTemplate, sortable: false, style: { width: "100px" } },
        { field: "statuses.status", header: "Status", body: statusTemplate, sortable: false, style: { width: "100px" } },
        { field: "jobNumber", header: "Job Num", sortable: false, style: { width: "500px" } },
        { field: "ER", header: "Total Job Estimate", sortable: false, style: { width: "250px" } },
        { field: "totalStopWatchTime", header: "Total Job Time", sortable: false, style: { width: "250px" } },
        { field: "createdAt", header: "Created Date", body: createDateTemplate, sortable: false, style: { width: "200px" } },
        { field: "creater.firstName", header: "Created By", body: createdByTemplate, sortable: false, style: { width: "150px" } },
        { field: "productionTime", header: "Production Complete", body: productionDateTemplate, sortable: false, style: { width: "100px" } },
        { field: "deliveryTime", header: "Delivery Complete", body: deliveryDateTemplate, sortable: false, style: { width: "100px" } },
        { field: "updatedAt", header: "Updated At", body: updateDateTemplate, sortable: true, style: { width: "100px" } },
    ];

    const recentQueueColumns = [
        { field: "client.clientName", header: "Client", sortable: false },
        { field: "project.name", header: "Project", sortable: false },
        { field: "jobReference", header: "Job Name", sortable: false },
        { field: "bpsUsers", header: "BPS", body: bpsUsersTemplate, sortable: false },
        { field: "qcsUsers", header: "QC", body: qcUsersTemplate, sortable: false },
        {
            field: timeZoneIST ? "deadlineIST" : "deadline",
            header: timeZoneIST ? "Deadline (IST)" : "Deadline (UK)",
            body: deadlineTemplate,
            sortable: false,
            style: { width: '130px'}
        },
        { field: "job_priority.priority", header: "Priority", body: priorityTemplate, sortable: false },
        { field: "statuses.status", header: "Status", body: statusTemplate, sortable: false },
        { field: "jobNumber", header: "Job Num", sortable: false },
        { field: "createdAt", header: "Created Date", body: createDateTemplate, sortable: false },
        { field: "creater.firstName", header: "Created By", body: createdByTemplate, sortable: false },
        { field: "updatedAt", header: "Updated At", body: updateDateTemplate, sortable: false },
    ];

    return (
        <div className="mt-20 business-unit" id="serviceDesk">
            <ToastElement ref={toastRef} />
            <Loader loading={loading} />
            {
                displayCreateModal &&
                <TrackingSystemCreateModal visible={true} header="New Job" type="Request" onHide={(message, success) => onHide('displayCreateModal', message, success)} />
            }
            {
                datatable && (
                    <TabView activeIndex={activeIndex} onTabChange={(e) => { setData(e); setActiveIndex(e.index); localStorage.setItem("activeTabIndex", e.index); }}>
                    {/* // <TabView activeIndex={activeIndex} onTabChange={(e) => { setData(e); setActiveIndex(e.index); */}
                    {/* //     const params = new URLSearchParams(location.search);
                    //     params.set("tab", e.index);
                    //     navigate(`?${params.toString()}`, { replace: true }); */}
                    {/* //  }}> */}

                        <TabPanel header="All Queue">
                            <div className='servicedesk-tabview'>
                                <DataTableElement
                                    value={allQueueList}
                                    columns={columns}
                                    button={button}
                                    selectionMode="single"
                                    createButton={
                                        <Button label="Create New Job" onClick={createView} icon="pi pi-plus" />
                                    }
                                    placeholder="Search Here"
                                    selectedId={selectData}
                                    onChangeFilter={e => onChangeFilter(e)}
                                    jobTracking={true}
                                    paginationView={false}
                                    header={true}
                                    search={true}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel header="My Queue">
                            <div className="servicedesk-tabview">
                                <DataTableElement
                                    value={myQueueList}
                                    columns={columns}
                                    button={button}
                                    selectionMode="single"
                                    createButton={
                                        <Button label="Create New Job" onClick={createView} icon="pi pi-plus" />
                                    }
                                    placeholder="Search Here"
                                    selectedId={selectData}
                                    onChangeFilter={e => onChangeFilter(e)}
                                    jobTracking={true}
                                    paginationView={false}
                                    header={true}
                                    search={true}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel header="Completed Queue">
                            <div className='servicedesk-tabview'>
                                <DataTableElement
                                    value={completedQueueList}
                                    columns={completedQueueColumns}
                                    button={button}
                                    selectionMode="single"
                                    createButton={
                                        <Button label="Create New Job" onClick={createView} icon="pi pi-plus" />
                                    }
                                    placeholder="Search Here"
                                    selectedId={selectData}
                                    onChangeFilter={e => onChangeFilter(e)}
                                    jobTracking={true}
                                    paginationView={false}
                                    header={true}
                                    search={true}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel header="Recent Queue">
                            <div className="servicedesk-tabview">
                                <DataTableElement
                                    value={recentQueueList}
                                    columns={recentQueueColumns}
                                    button={button}
                                    selectionMode="single"
                                    createButton={
                                        <Button label="Create New Job" onClick={createView} icon="pi pi-plus" />
                                    }
                                    placeholder="Search Here"
                                    selectedId={selectData}
                                    onChangeFilter={e => onChangeFilter(e)}
                                    jobTracking={true}
                                    paginationView={false}
                                    header={true}
                                    search={true}
                                />
                            </div>
                        </TabPanel>
                    </TabView>
                )
            }
        </div>
    )
}

export default TrackingSystem;