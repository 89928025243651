/* Import Section - Start */

/* React Imports - Start */

import React, { useEffect, useRef, useState } from "react";

/* React Imports - End */

/* Plugin Imports - Start */

import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Ripple } from 'primereact/ripple';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import ButtonElement from "../uiComponents/ButtonElement";
import * as XLSX from 'xlsx';
import CalendarElement from "./CalendarElement";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import DateTimeFunctions from "../../util/DateTimeFunctions";
import FilterViews from "../uiComponents/FilterViews";
import { useNavigate } from "react-router";
/* Plugin Imports - End */

/* Import Section - End */

/* Function - Start */

const DataTableElement = (props) => {

    /* State Declaration - Start */

    /* useState - Start */
    const navigate = useNavigate();
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRows, setTotalRows] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState('');
    const [selectedData, setSelectedData] = useState();
    const [pageReport, setPageReport] = useState({
        start: props.startIndex,
        end: props.startIndex + 9
    });
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [timeSheetData, setTimeSheetData] = useState([]);
    const [multiSortMeta, setMultiSortMeta] = useState([{ field: 'category', order: -1 }]);
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        page: 1,
        // sortField: null,
        // sortOrder: null,
        search: ""
    });
    let loadLazyTimeout = null;
    /* useState - End */

    /* State Declaration - End */

    useEffect(() => {
        setSelectedData(selectedProducts)
    }, [selectedProducts])

    useEffect(() => {
        if (props.footer) {
            setTimeSheetData(props.footer);
        }
    }, [props.footer])

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        if (props.columns) {
            props.columns.map(col => {
                setFilters(state => ({ ...state, [col.field]: { value: null, filterMatchMode: FilterMatchMode.CONTAINS } }))
            })
        }
    }, [])

    // set inner filter state based on outer state button action
    useEffect(() => {
        if (props.columns) {
            props.columns.map(col => {
                setFilters(state => ({ ...state, [col.field]: { value: null, filterMatchMode: FilterMatchMode.CONTAINS } }))
            })
        }
    }, [props?.getList])

    /* Methods - Start */

    const onSort = (e) => {
        setMultiSortMeta(e.multiSortMeta);
        props.multiSort && props.multiSort(e);
    }

    const getSelectedId = (e) => {
        setSelectedProducts(e.value)
    }

    const onFilterInputChange = (e, data) => {
        let parsedDate = data.target.value
        const day = String(parsedDate.getDate()).padStart(2, '0')
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
        const year = String(parsedDate.getFullYear())
        const formattedDate = `${day}-${month}-${year}`
        setFilters(state => ({ ...state, [e.field]: { value: formattedDate, matchMode: FilterMatchMode.EQUALS } }))
    }

    const getJsDate = (str) => {
        let split = str.split('-')
        let date = new Date()
        date.setFullYear(split[2])
        date.setMonth(parseInt(split[1]) - 1)
        date.setDate(split[0])
        return date
    }
    const FilterInput = (e) => {
        return (
            <div className="datatable-filter-popup">
                <CalendarElement value={filters[e?.field]?.value ? getJsDate(filters[e?.field]?.value) : null} onChange={(data) => onFilterInputChange(e, data)} dateFormat="dd-mm-yy" inline={true} />
            </div>
        )
    }

    const onClearFilter = (e) => {
        setFilters(state => ({ ...state, [e?.field]: { value: null, filterMatchMode: FilterMatchMode.CONTAINS } }))
    }

    const FilterClear = (e) => {
        return <>
            <ButtonElement label="Clear" className="p-button p-component create-button" onClickButton={() => onClearFilter(e)} disabled={!filters?.[e?.field]?.value} />
        </>
    }

    const dynamicColumns = props.columns.map((col) => {
        return (
            col.field ?
                col.field === "expander" ? <Column expander field={col.field} style={{ width: '0.25%' }} headerClassName={col.headerClassName} ><ButtonElement icon="pi pi-plus" tooltip="Expand All" text /></Column> : <Column key={col.field} field={col.field} editable="false" header={col.header} body={col.body} sortable={col.sortable} style={col.style} headerClassName={col.headerClassName} filter={col.showFilter} filterMatchMode="contains" showFilterMatchModes={false} showApplyButton={false} filterClear={FilterClear} filterElement={FilterInput} className="datatable-column-style" />
                :
                <Column key={col.field} className={col.field} field={col.field} header={col.header} colSpan={col.colSpan} body={col.header} selectionMode={"multiple"} />
        )
    })

    const dynamicHeaderColumns =
        <ColumnGroup>
            <Row>
                {
                    props.performancedata ? props.performancedata.map((col) => {
                        return (
                            <Column key={col.field} field={col.field} header={col.header} colSpan={col.colSpan} headerStyle={col.style} className={col.className} />
                        )
                    }) : ""
                }
            </Row>
        </ColumnGroup>;

    const dynamicFooterColumns =
        <ColumnGroup>
            {
                Array.isArray(timeSheetData[0]) ? 
                    timeSheetData.map((col) => {
                        if (col.length > 0) {
                            {
                                return <Row>
                                    {
                                        col.map((c1) => {
                                            return (
                                                <Column key={c1.field} field={c1.field} footer={c1.footer} colSpan={c1.colSpan} footerStyle={c1.style} className={c1.className} />
                                            )
                                        })
                                    }
                                </Row>
                            }
                        }
                    })
                    : <Row>
                        {
                            timeSheetData.map((col) => {
                                return (
                                        <Column key={col.field} field={col.field} footer={col.footer} colSpan={col.colSpan} footerStyle={col.style} className={col.className} />
                                    
                                )
                            })
                        }
                    </Row>
            }
        </ColumnGroup>;

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const onLazyFilterChange = (e) => {
        const value = e.target.value;
        let lazyData = { ...lazyParams }
        lazyData.search = value
        setLazyParams(lazyData);
    }

    const onCustomPage = (event) => {
        if (event.target.classList.contains('pi-angle-left')) {
            let d = pageReport.start - 1;
            pageReport['end'] = d;
            pageReport['start'] = pageReport.end - 9;

        } else if (event.target.classList.contains('pi-angle-right')) {
            let s = pageReport.end + 1;
            pageReport['start'] = s;
            pageReport['end'] = pageReport.start + 9;

        }
        props.onPageChange(event);
    }

    const onClickRow = (event) => {
        props.onRowClick(event);
    }

    const rowClassName = (event) => {
        if (event?.hubspotId != undefined) {
            if (event?.hubspotId === null || event?.hubspotId === '') {
                return 'row-diff';
            }
        }
        // else {
        //     if (event?.typeOfProject === 'Client Project') {
        //         return 'row-diff';
        //     }
        // }
    }
    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(() => {
            setLoading(false);
            if (props.onChangeFilter) {
                props.onChangeFilter(lazyParams);
            }
        }, Math.random() * 1000 + 250);
    }

    const onClickDownload = () => {

        let summaryData = [];
        let projectAllocations = [];
        let leave = [];
        let nextWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['1']}`
        let nextTwoWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['2']}`
        let nextThreeWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['3']}`
        let nextFourWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['4']}`
        let nextFiveWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['5']}`
        let nextSixWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['6']}`
        let nextSevenWeekDate = `W/C ${DateTimeFunctions.getWeeksFirstDateText()['7']}`
        props.value.forEach(data => {
            let objData = {
                ResourceName: data.firstName + ' ' + data.lastName,
                ResourceBUName: data.name,
                ResourceDesignation: data.designation,
                proposed: parseFloat(data.totalEffort.proposed ? data.totalEffort.proposed : 0),
                billed: parseFloat(data.totalEffort.billable ? data.totalEffort.billable : 0),
                shadow: parseFloat(data.totalEffort.shadow ? data.totalEffort.shadow : 0),
                CurrentStatus: data.current_status,
            }
            objData[nextWeekDate] = data.next_status
            objData[nextTwoWeekDate] = data.next_two_week_status
            objData[nextThreeWeekDate] = data.future_status
            objData[nextFourWeekDate] = data.fifth_week
            objData[nextFiveWeekDate] = data.sixth_week
            objData[nextSixWeekDate] = data.seventh_week
            objData[nextSevenWeekDate] = data.eigth_week
            summaryData.push(objData)
        })
        props.value.forEach(data => {
            data.values.forEach(data => {
                if (data.Project) {
                    projectAllocations.push({
                        ResourceName: data.firstName + ' ' + data.lastName,
                        ResourceBUName: data.name,
                        ResourceDesignation: data.designation,
                        Client: data.clientName,
                        Project: data.Project.name,
                        ProjectCode: data.Project.code,
                        ProjectApprover: data.approverData.firstName + ' ' + data.approverData.lastName,
                        Status: data.billing_status,
                        "Utilization(%)": parseFloat(data.allocationByPercentage),
                        ProjectStartDate: moment(data.Project.startDate, 'DD-MM-YYYY').format('DD/MM/YYYY'),
                        ProjectEndDate: moment(data.Project.endDate, 'DD-MM-YYYY').format('DD/MM/YYYY'),
                        AllocationStartDate: moment(data.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                        AllocationEndDate: moment(data.endDate, 'YYYY_MM_DD').format('DD/MM/YYYY')
                    })
                }
            })
        })
        props.value.forEach(data => {
            data.values.forEach(data => {
                if (data.hasOwnProperty('Project')) {
                    if (data.Project == '') {
                        leave.push({
                            ResourceName: data.firstName + ' ' + data.lastName,
                            ResourceBUName: data.name,
                            ResourceDesignation: data.designation,
                            LeaveType: data.leaveTypeName,
                            From: moment(data.from_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                            To: moment(data.to_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                            NoOfDays: data.total,
                            RequestStatus: data.leaveStatus
                        })
                    }
                }
            })
        })
        const summaryWorksheet = XLSX.utils.json_to_sheet(summaryData, { autoWidth: true });
        const projectAllocationWorksheet = XLSX.utils.json_to_sheet(projectAllocations, { autoWidth: true });
        const leaveSheet = XLSX.utils.json_to_sheet(leave, { autoWidth: true })
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, summaryWorksheet, 'Summary');
        XLSX.utils.book_append_sheet(workbook, projectAllocationWorksheet, 'Project Allocations');
        XLSX.utils.book_append_sheet(workbook, leaveSheet, 'Leave');
        XLSX.writeFile(workbook, `${moment().format('YYMMDD')}resource_data.xlsx`);
    }

    const onPage = (event) => {
        let page = { ...lazyParams, ...event };
        if (lazyParams.first !== event.first) {
            page = {...page, 'page': event.page+1}
            setLazyParams(page);
        }
    }

    const onSortLazy = (event) => {
        let sort = { ...lazyParams, ...event }
        setLazyParams(sort);
    }

    /* Methods - End */

    /* Render Templates - Start */

    const renderHeader = () => {
        if (props.header) {
            if (props.wsrHeader) {
                return (
                    <>
                        <div>
                            {props.filterButton && props.filterButton}
                        </div>
                        <div className="p-grid m-0 align-center justify-between pt-10">
                            <div className="d-flex">
                                {
                                    props.search &&
                                    <div className="table-header">
                                        <span className="p-input-icon-left">
                                            <i className="pi pi-search" />
                                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} type="search" placeholder={props.placeholder} className="fs-12" />
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className="d-flex">
                                {props.dateRange && props.dateRange}
                            </div>
                        </div>
                    </>
                )
            } else {
                return (
                    <div className="p-grid m-0 align-center justify-between">
                        <div className="d-flex">
                            {props.filterButton && props.filterButton}
                            {
                                props.search &&
                                <div className="table-header">
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} type="search" placeholder={props.placeholder} className="fs-12" />
                                    </span>
                                </div>
                            }
                        </div>
                        <div className="d-flex">
                            {props.button && props.createButton}
                        </div>
                        {
                            props.download &&
                                <div className="d-flex w-100" style={{ justifyContent: 'flex-end', paddingBottom: '6px', paddingTop: '7px' }}>
                                    <div className="mt-5 userFilter"><ButtonElement
                                        className=" fs-13 font-normal"
                                        label=""
                                        icon="pi pi-download"
                                        onClickButton={() => onClickDownload()}
                                    /></div>
                                    <div >
                                        <FilterViews pageName="Resourcing" filter={props.filterViewData} filterFunction={props.filterFunction} />
                                    </div>
                                </div>
                        }
                    </div>
                )
            }
        }
        if (props.headerName) {
            return (
                <div className="header-title">
                    {props.headerTitle}
                </div>
            )
        }
    }

    const renderLazyHeader = () => {
        if (props.header) {
            return (
                <div className="p-grid m-0 align-center justify-between">
                    <div className="d-flex">
                        {props.filterButton && props.filterButton}
                        {
                            props.search &&
                            <div className="table-header">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText value={lazyParams.search} onChange={onLazyFilterChange} type="search" placeholder={props.placeholder} className="fs-12" />
                                </span>
                            </div>
                        }
                    </div>
                    <div className="d-flex">
                        {props.button && props.createButton}
                    </div>
                </div>
            )
        }
        if (props.headerName) {
            return (
                <div className="header-title">
                    {props.headerTitle}
                </div>
            )
        }
    }

    const paginatorTemplate = {
        layout: `RowsPerPageDropdown CurrentPageReport ${totalRows > 20 ? 'PrevPageLink  NextPageLink' : ''}`,
        'CurrentPageReport': (options) => {
            setTotalRows(options.totalRecords);
            if (totalRows > 20) {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} - {options.last} of {options.totalRecords}
                    </span>
                )
            }
            else {
                return false;
            }
        }
    };

    const template1 = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        'PrevPageLink': (options) => {
            let disabled;
            if (props.totalPages == 1 || props.className) {
                disabled = 'p-disabled'
            }

            return (
                <button type="button" className={'p-paginator-prev p-paginator-element p-link ' + disabled} onClick={(e) => onCustomPage(e)}>
                    <span className="p-paginator-icon pi pi-angle-left"></span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className='p-paginator-prev p-paginator-element p-link' onClick={(e) => onCustomPage(e)}>
                    <span className="p-paginator-icon pi pi-angle-right"></span>
                    <Ripple />
                </button>
            )
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {pageReport.start} - {pageReport.end} of {props.totalRecords}
                </span>
            )
        }
    };
    const dynamicHeader  =   
        <ColumnGroup>
            <Row>
                {
                    props.wsrFirstData ? props.wsrFirstData.map((col) => {
                        return (
                            <Column key={col.field} header={col.header} field={col.field}  colSpan={col.colSpan} headerStyle={col.style} className={col.className} sortable={col.sortable} rowSpan={col.rowSpan} />
                        )
                    }) : ""
                }
            </Row>
            <Row>
                {
                    props.wsrSecondData ? props.wsrSecondData.map((col) => {
                        return (
                            <Column key={col.field} header={col.header} field={col.field}  colSpan={col.colSpan} headerStyle={col.style} className={col.className} sortable={col.sortable} />
                        )
                    }) : ""
                }
            </Row>
        </ColumnGroup>;

        const handleRowClick = (event) => {
            let selectedData = event.data;
            var url = '/jobs/' + selectedData.id + '/bu/' + selectedData.buId;
            navigate(url, { state: { selectedData } });
        }

    /* Render Templates - End */

    /* Render View Return - Start */

    return (
        <div>
            <div>
                {
                    props.survey &&
                    <DataTable value={props.value} header={renderHeader} responsiveLayout="scroll" filters={filters} paginator paginatorTemplate={paginatorTemplate} first={first} rows={rows} selection={selectedProducts} selectedId={props.selectedId(selectedData)} onSelectionChange={getSelectedId}
                        onRowClick={props.userSurvey ? (e) => onClickRow(e) : null}
                        showSelectionElement={row => (row.leaveStatus === "REQUESTED")} removableSort resizableColumns columnResizeMode="fit" showFilterMenu={true} rowClassName={rowClassName} footerColumnGroup={props.colGroup ? dynamicFooterColumns : ''} selectionMode={props.selectionMode} dataKey="id" id={props.id}>
                        {dynamicColumns}
                    </DataTable>
                }
            </div>
            <div>
                {
                   props.wsr&& !props.paginationView && !props.expand && !props.survey && !props.rowGroup && !props.serviceDesk && !props.competency &&
                    <DataTable value={props.value} header={renderHeader}  responsiveLayout="scroll" filters={filters} paginator paginatorTemplate={paginatorTemplate} first={first} rows={rows} selection={selectedProducts} selectedId={props.selectedId(selectedData)} onSelectionChange={getSelectedId} showSelectionElement={row => (row.leaveStatus === "REQUESTED")} removableSort resizableColumns columnResizeMode="fit" showFilterMenu={true} rowClassName={rowClassName} footerColumnGroup={props.colGroup ? dynamicFooterColumns : ''} selectionMode={props.selectionMode} dataKey="id" id={props.id} onSort={onSort} multiSortMeta={multiSortMeta} sortMode="multiple">
                        {dynamicColumns}
                    </DataTable>
                }
            </div>
            <div>
                {
                  !props.wsr&&  !props.paginationView && !props.expand && !props.survey && !props.rowGroup && !props.serviceDesk && !props.competency && !props.jobTracking && !props.inputOutputFiles && 
                    <DataTable value={props.value} header={renderHeader} responsiveLayout="scroll" filters={filters} paginator paginatorTemplate={paginatorTemplate} first={first} rows={rows} selection={selectedProducts} selectedId={props.selectedId(selectedData)} onSelectionChange={getSelectedId} showSelectionElement={row => (row.leaveStatus === "REQUESTED")} removableSort resizableColumns columnResizeMode="fit" showFilterMenu={true} rowClassName={rowClassName} footerColumnGroup={props.colGroup ? dynamicFooterColumns : ''} selectionMode={props.selectionMode} dataKey="id" id={props.id} onSort={onSort} multiSortMeta={multiSortMeta} sortMode="multiple">
                        {dynamicColumns}
                    </DataTable>
                }
            </div>
            <div>
                {
                    props.paginationView &&
                    <DataTable value={props.value} header={renderHeader} responsiveLayout="scroll" filters={filters} paginator paginatorTemplate={template1} first={first} rows={rows} selection={selectedProducts} selectedId={props.selectedId(selectedData)} onSelectionChange={getSelectedId} onPage={e => onCustomPage(e)} onRowClick={e => onClickRow(e)} showSelectionElement={row => (row.leaveStatus === "REQUESTED")} removableSort resizableColumns columnResizeMode="fit" showFilterMenu={true} rowClassName={rowClassName}>
                        {dynamicColumns}
                    </DataTable>
                }
                {
                    props.rowGroup &&
                    <DataTable value={props.value} header={renderHeader} responsiveLayout="scroll" rowGroupMode={props.rowGroupMode} groupRowsBy={props.groupRowsBy} sortMode="single" sortField={props.groupRowsBy} sortOrder={props.sortOrder ? props.sortOrder : 1} footerColumnGroup={props.colGroup ? dynamicFooterColumns : ''}>
                        {dynamicColumns}
                    </DataTable>
                }
            </div>
            <div>
                {
                    props.expand && !props.paginationView && !props.resourcing &&
                    <DataTable value={props.value} expandedRows={props.expandedRows}
                        header={renderHeader} onRowToggle={props.onRowToggle} onRowExpand={props.onRowExpand} responsiveLayout="scroll" rowExpansionTemplate={props.rowExpansionTemplate} datakey="id" footerColumnGroup={props.colGroup ? dynamicFooterColumns : ''}
                        headerColumnGroup={props.colGroup ? dynamicHeaderColumns : ''}   >
                        {dynamicColumns}
                    </DataTable>

                }
            </div>
            <div>
                {
                    props.expand && !props.paginationView && props.resourcing &&
                    <DataTable
                        value={props.value}
                        expandedRows={props.expandedRows}
                        header={renderHeader}
                        onRowToggle={props.onRowToggle}
                        onRowExpand={props.onRowExpand}
                        responsiveLayout="scroll"
                        scrollable={true}
                        rowExpansionTemplate={props.rowExpansionTemplate}
                        datakey="id"
                        footerColumnGroup={props.colGroup ? dynamicFooterColumns : ''}
                        headerColumnGroup={props.colGroup ? dynamicHeaderColumns : ''}   >
                        {dynamicColumns}
                    </DataTable>

                }
            </div>
            <div>
                {
                    props.jobTracking &&
                    <DataTable value={props.value.job} lazy responsiveLayout="scroll" dataKey="id" paginator paginatorTemplate={paginatorTemplate} first={lazyParams.first} header={renderLazyHeader} rows={20} totalRecords={props.value.count} onPage={e => onPage(e)}
                        onSort={e => onSortLazy(e)} headerColumnGroup={props.colGroup ? dynamicHeaderColumns : ''} scrollable={true}  tableStyle={{minWidth: '100rem'}} onRowClick={handleRowClick}>
                        {dynamicColumns}
                    </DataTable>
                }
            </div>
            <div>
                {
                    props.serviceDesk &&
                    <DataTable value={props.value.rows} lazy responsiveLayout="scroll" dataKey="id" paginator paginatorTemplate={paginatorTemplate} first={lazyParams.first} header={renderLazyHeader} rows={20} totalRecords={props.value.count} onPage={e => onPage(e)}
                        onSort={e => onSortLazy(e)} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} headerColumnGroup={props.colGroup ? dynamicHeaderColumns : ''} >
                        {dynamicColumns}
                    </DataTable>
                }
            </div>
            <div>
                {
                    props.competency &&
                    <DataTable value={props.value} header={renderHeader} responsiveLayout="scroll" filters={filters} paginatorTemplate={paginatorTemplate} first={first} rows={rows} selection={selectedProducts} selectedId={props.selectedId(selectedData)} onSelectionChange={getSelectedId} showSelectionElement={row => (row.leaveStatus === "REQUESTED")} removableSort resizableColumns columnResizeMode="fit" showFilterMenu={true} rowClassName={rowClassName} footerColumnGroup={props.colGroup ? dynamicFooterColumns : ''} selectionMode={props.selectionMode} dataKey="id" id={props.id} onSort={onSort} multiSortMeta={multiSortMeta} sortMode="multiple">
                        {dynamicColumns}
                    </DataTable>
                }
            </div>
            <div>
                {
                    props.inputOutputFiles &&
                    <DataTable value={props.value} header={renderHeader} responsiveLayout="scroll" filters={filters} selection={selectedProducts} selectedId={props.selectedId(selectedData)} onSelectionChange={getSelectedId} removableSort resizableColumns columnResizeMode="fit" showFilterMenu={true} rowClassName={rowClassName} footerColumnGroup={props.colGroup ? dynamicFooterColumns : ''} selectionMode={props.selectionMode} dataKey="id" id={props.id} onSort={onSort} multiSortMeta={multiSortMeta} sortMode="multiple">
                        {dynamicColumns}
                    </DataTable>
                }
            </div>
        </div>
    );

    /* Render View Return - End */

};

/* Function - End */

/* Export default functionName; */

export default DataTableElement;
